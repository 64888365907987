// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz3 from '../../assets/images/quiz-bg/Quiz3.png'

export const topic8: Topic = {
  topic: 'Áp lực xã hội và đồng trang lứa',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi thường tham gia các hoạt động chỉ để được bạn bè công nhận.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Đôi khi ý kiến của đám đông tạo áp lực làm tôi phải chọn theo mặc dù tôi không hoàn toàn đồng tình",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi dễ dàng thay đổi ý kiến khi nhận thấy đám đông không ủng hộ.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi phải tham gia các xu hướng vì tôi sợ bị lạc hậu so với bạn bè.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy không áp lực khi từ chối những quan điểm của nhóm đưa ra.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường lo lắng về việc bị nhóm phê bình nếu có quan điểm khác.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy thoải mái khi thể hiện ý kiến riêng của mình trước bạn bè.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường từ bỏ ý kiến cá nhân để tránh gây mâu thuẫn trong nhóm.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi dễ dàng vượt qua áp lực đồng trang lứa để đưa ra quyết định hợp lý.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy cần phải tuân theo ý kiến nhóm để tránh bị cô lập.",
      image: Quiz3,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
  ],
}
