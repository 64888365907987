// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz2 from '../../assets/images/quiz-bg/Quiz2.png'

export const topic7: Topic = {
  topic: 'Ảnh hưởng cảm xúc từ đám đông',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi dễ bị cuốn theo cảm xúc của nhóm trong các tình huống sôi động như cổ vũ",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường hành động khác đi khi ở trong nhóm đông người so với khi ở một mình",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy mình khó kiểm soát cảm xúc khi bị tác động bởi đám đông",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi dễ dàng nhận ra cảm xúc của mình bị ảnh hưởng bởi nhóm",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường hành động dựa trên cảm xúc của người khác hơn là lý trí của mình",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi đôi khi cảm thấy an toàn hơn khi đưa ra quyết định theo số đông",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng cảm xúc của nhóm thường mang lại năng lượng tích cực",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thấy mất bình tĩnh khi cả nhóm đang tranh luận căng thẳng",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy tự tin khi dám đưa ra ý kiến khác so với ý kiến chung của nhóm",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi giữ vững quan điểm cá nhân dù cho bị cảm xúc nhóm tác động",
      image: Quiz2,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
