import { topic1 } from './topic1'
import { topic2 } from './topic2'
import { topic3 } from './topic3'
import { topic4 } from './topic4'
import { topic5 } from './topic5'

import { topic6 } from './topic6'
import { topic7 } from './topic7'
import { topic8 } from './topic8'
import { topic9 } from './topic9'
import { topic10 } from './topic10'
import { topic11 } from './topic11'
import { topic12 } from './topic12'
import { topic13 } from './topic13'


// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

type Choice = string
type CorrectAnswers = string[]

export type Question = {
  question: string
  choices: Choice[]
  type: 'MCQs' | 'MAQs' | 'boolean'
  correctAnswers: CorrectAnswers
  score: number
  code?: string
  image?: string
}

export type Topic = {
  topic: string
  level: string
  totalQuestions: number
  totalScore: number
  totalTime: number
  questions: Question[]
}

export const quiz: Record<string, Topic> = {
  topic1,
  topic2,
  topic3,
  topic4,
  topic5,
  topic6,
  topic7,
  topic8,
  topic9,
  topic10,
  topic11,
  topic12,
  topic13,
}
