// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz6 from '../../assets/images/quiz-bg/Quiz6.png'

export const topic11: Topic = {
  topic: 'Ảnh hưởng từ mạng xã hội và truyền thông',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi dễ dàng tin tưởng các thông tin nếu thấy nhiều người chia sẻ trên mạng xã hội.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường kiểm tra nguồn gốc thông tin trước khi chia sẻ trên mạng xã hội.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy áp lực phải đăng bài trên mạng xã hội để nhận sự chú ý từ bạn bè.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường cân nhắc kỹ lưỡng trước khi bình luận về bài viết của người khác.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng lượt thích và chia sẻ là tiêu chí để đánh giá độ tin cậy của thông tin.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy mình phải theo dõi các xu hướng do mạng xã hội tạo ra vì nó thú vị.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi dễ dàng thay đổi quan điểm khi thấy ý kiến đa số trong các bình luận trực tuyến.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường tránh tham gia tranh luận trên mạng xã hội để tránh gây xung đột.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường tự kiểm chứng thông tin trên mạng xã hội trước khi áp dụng vào cuộc sống.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy mạng xã hội giúp tôi mở rộng hiểu biết về quan điểm của người khác.",
      image: Quiz6,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
