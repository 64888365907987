import { ReactNode } from 'react'
import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg'

type QuizTopic = {
  title: string
  alias: string
  icon: ReactNode
  disabled?: boolean
}

export const quizTopics: QuizTopic[] = [
  {
    title: 'Nhận biết',
    alias: 'topic1',
    icon: <BulbIcon />,
  },
  {
    title: 'Tình huống',
    alias: 'topic2',
    icon: <BulbIcon />,
  },
  {
    title: 'Thông hiểu',
    alias: 'topic3',
    icon: <BulbIcon />,
  },
  {
    title: 'Vận dụng',
    alias: 'topic4',
    icon: <BulbIcon />,
  },
  {
    title: 'Vận dụng cao',
    alias: 'topic5',
    icon: <BulbIcon />,
  },
]


export const twoOptionQuizTopics: QuizTopic[] = [
  {
    title: 'Nhận thức cá nhân và ý kiến đám đông',
    alias: 'topic6',
    icon: <BulbIcon />,
  },
  {
    title: 'Ảnh hưởng cảm xúc từ đám đông',
    alias: 'topic7',
    icon: <BulbIcon />,
  },
  {
    title: 'Áp lực xã hội và đồng trang lứa',
    alias: 'topic8',
    icon: <BulbIcon />,
  },
  {
    title: 'Tâm lý đám đông trong môi trường học đường',
    alias: 'topic9',
    icon: <BulbIcon />,
  },
  {
    title: 'Hành vi và quyết định trong đám đông',
    alias: 'topic10',
    icon: <BulbIcon />,
  },
  {
    title: 'Ảnh hưởng từ mạng xã hội và truyền thông',
    alias: 'topic11',
    icon: <BulbIcon />,
  },
  {
    title: 'Phản ứng trong tình huống tiêu cực từ đám đông',
    alias: 'topic12',
    icon: <BulbIcon />,
  },
  {
    title: 'Giá trị cá nhân và sự khác biệt',
    alias: 'topic13',
    icon: <BulbIcon />,
  },
]
