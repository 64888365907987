// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz5 from '../../assets/images/quiz-bg/Quiz5.png'

export const topic10: Topic = {
  topic: 'Hành vi và quyết định trong đám đông',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi thường suy nghĩ cẩn thận trước khi làm theo hành vi của nhóm.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường suy nghĩ rồi đồng ý với ý kiến của nhóm hơn là tranh luận.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường tự hỏi liệu hành động của nhóm có thực sự đúng đắn không.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy không thoải mái khi phải đưa ra quyết định trái ngược với ý kiến nhóm.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường giữ vững lập trường ngay cả khi nhóm không đồng tình.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng việc tuân theo nhóm là cách tốt nhất để tránh rắc rối.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thấy khó từ chối lời mời tham gia các hoạt động nhóm dù không thực sự thích.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường giải thích về quan điểm của mình và thuyết phục nhóm làm theo.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy yên tâm hơn khi quyết định dựa vào ý kiến của số đông.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi luôn xem xét tác động lâu dài trước khi hành động theo nhóm.",
      image: Quiz5,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
