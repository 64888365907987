// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz1 from '../../assets/images/quiz-bg/Quiz1.png'

export const topic6: Topic = {
  topic: 'Nhận thức cá nhân và ý kiến đám đông',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi thường kiểm tra tính chính xác của thông tin trước khi làm theo ý kiến của nhóm/tập thể",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi dễ dàng nhận ra khi mình bị ảnh hưởng bởi áp lực từ đám đông",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng ý kiến cá nhân thường quan trọng hơn ý kiến của số đông",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường xem xét lại quyết định của mình sau khi làm theo đám đông",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy khó khăn khi phải phản đối ý kiến của nhóm và bảo vệ ý kiến của bản thân",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường không nhận ra mình dễ dàng bị cuốn theo ý kiến của người khác",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin tưởng rằng suy nghĩ của số đông là luôn đúng",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thích đưa ra quyết định độc lập mà không phụ thuộc vào người khác",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường xuyên thay đổi ý kiến khi thấy nhiều người không đồng tình",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy tự tin khi bảo vệ ý kiến cá nhân trong nhóm",
      image: Quiz1,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
