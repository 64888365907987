import { ReactNode } from 'react'
import { ReactComponent as BulbIcon } from '../assets/icons/bulb.svg'

type QuizParentTopic = {
  title: string
  alias: string
  icon: ReactNode
  disabled?: boolean
}

export const quizParentTopics: QuizParentTopic[] = [
  {
    title: 'Đồng ý - Không đồng ý',
    alias: 'parenttopic1',
    icon: <BulbIcon />,
  },
  {
    title: 'Trắc nghiệm',
    alias: 'parenttopic2',
    icon: <BulbIcon />,
  },
]
