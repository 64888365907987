// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz8 from '../../assets/images/quiz-bg/Quiz8.png'

export const topic13: Topic = {
  topic: 'Phản ứng trong tình huống tiêu cực từ đám đông',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi đánh giá cao sự khác biệt trong quan điểm của mọi người.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường thấy thoải mái khi ở trong nhóm có nhiều ý kiến trái chiều.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng việc thảo luận/tranh luận là cách tốt nhất để hiểu rõ ý tưởng của người khác và đưa ra cách giải quyết vấn đề hiệu quả hơn.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy khó chấp nhận khi người khác không đồng ý với mình.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy thoải mái làm việc nhóm với những người có quan điểm khác nhau.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy thoải mái để bày tỏ ý kiến trong một nhóm đa dạng.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thấy giá trị của mình thường bị lu mờ khi làm việc trong nhóm.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng mỗi người đều có quyền giữ vững lập trường, quan điểm cá nhân.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy được kích thích kĩ năng phản biện khi nghe các ý kiến khác nhau.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường ưu tiên tìm hiểu quan điểm nhóm trước khi quyết định.",
      image: Quiz8,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
