// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz4 from '../../assets/images/quiz-bg/Quiz4.png'

export const topic9: Topic = {
  topic: 'Tâm lý đám đông trong môi trường học đường',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi nên thay đổi cách cư xử khi thấy bạn bè mình làm khác đi.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi nên cảm thấy lo lắng nếu không tham gia các hoạt động mà nhóm bạn thân đã tham gia.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi nên được ảnh hưởng bởi phong cách ăn mặc hoặc hành vi của nhóm bạn học.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường nghe theo quyết định của nhóm mà không cân nhắc kỹ lưỡng.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin tưởng 100% rằng ý kiến của nhóm bạn thân là chính xác.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thấy thoải mái khi không tham gia các trào lưu nhóm bạn hưởng ứng mà trào lưu đó tôi không hứng thú.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy khó hòa nhập nếu không đồng quan điểm với số đông.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi tin rằng làm mọi việc theo đám đông có thể giúp xây dựng mối quan hệ tốt hơn.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường đứng ngoài các cuộc thảo luận khi không đồng ý với ý kiến của nhóm.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy tự hào khi ý kiến cá nhân của mình được cả nhóm đồng tình.",
      image: Quiz4,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
