// Question Types
// 1. MCQs | Multiple Choice | single
// 2. boolean | true/false | single
// 3. MAQs | Multiple Answers | multiple

import { Topic } from '.'
import Quiz7 from '../../assets/images/quiz-bg/Quiz7.png'

export const topic12: Topic = {
  topic: 'Phản ứng trong tình huống tiêu cực từ đám đông',
  level: 'Trung bình',
  totalQuestions: 10,
  totalScore: 100,
  totalTime: 300, // seconds
  questions: [
    {
      question: "Tôi sẵn sàng lên tiếng khi thấy hành vi bắt nạt trong nhóm bạn.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy sợ phải đối mặt với một nhóm người đang có ý kiến trái ngược với tôi.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường tham gia các cuộc tranh luận về quan điểm đúng đắn trong nhóm.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy mạnh mẽ hơn khi được bạn bè ủng hộ ý kiến của mình.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường đồng tình với nhóm dù nhận ra hành vi đó không đúng.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi luôn cố gắng giữ vững đạo đức cá nhân ngay cả khi bị áp lực từ nhóm.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy dễ dàng hợp tác với nhóm trong những tình huống cần đưa ra giải pháp.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường nhận ra sớm khi một đám đông đang đi chệch hướng.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
    {
      question: "Tôi cảm thấy rất khó khăn khi giải thích với đám đông về quan điểm của mình.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Không đồng ý'],
      score: 10,
    },
    {
      question: "Tôi thường thảo luận với nhóm để tìm ra giải pháp thay vì tranh cãi.",
      image: Quiz7,
      choices: ['Đồng ý', 'Không đồng ý'],
      type: 'boolean',
      correctAnswers: ['Đồng ý'],
      score: 10,
    },
  ],
}
